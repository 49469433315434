if (navigator.userAgent.match(/Android/i)) {
	showAppStoreLink("https://play.google.com/store/apps/details?id=com.fiz&hl=en&gl=US");
} else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
	showAppStoreLink("https://apps.apple.com/kw/app/fiz-groceries-in-minutes/id1575127077");
} else {
}

function showAppStoreLink(appStoreLink) {
	// Create a popup div with a link to the app store.
	var popup = document.createElement("div");
	popup.innerHTML = `
      <div style="position: fixed; display: flex; align-items: center; justify-content: center; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5);">
          <div style="width: 90%; background: white; padding: 20px; border-radius: 10px">
              <p>We have a mobile app! Install now from the App Store.</p>
              <a href="${appStoreLink}" target="_blank">Install App</a>
          </div>
      </div>
  `;

	// Add the popup to the document.
	document.body.appendChild(popup);
}
